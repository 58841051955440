export const AppleDevices: string[] = ['iPhone', 'iPad', 'iPod'];

export const DefaultMapScale = 18;

export const EstimateStatusColor = {
  ['In Progress']: '#c18989',
  ['Approved']: '#85ccc8',
  ['Scheduled']: '#e67c7c',
  ['Sent']: '#a7d6a8',
  ['Draft']: '#d0dcb1',
};

export const PhoneNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
