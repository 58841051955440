import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { JwtService } from '../jwt/jwt.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly _http: HttpClient, private readonly _jwt: JwtService) {}

  private formatErrors(error: any) {
    const {
      error: { Message: msg },
      status,
    } = error;
    return throwError({ msg, status });
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this._http.get(`${environment.apiUrl}${path}`, { params, observe: 'response' }).pipe(
      tap((res) => {
        const token = res.headers.get('JwtToken');
        this._jwt.saveToken(token);
      }),
      catchError(this.formatErrors)
    );
  }

  put(path: string, body: any): Observable<any> {
    const requestBody = JSON.stringify(body);
    return this._http
      .put(`${environment.apiUrl}${path}`, requestBody, {
        observe: 'response',
      })
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}${path}`, JSON.stringify(body), {
        observe: 'response',
      })
      .pipe(catchError(this.formatErrors));
  }

  delete(path, options): Observable<any> {
    return this._http.delete(`${environment.apiUrl}${path}`, options).pipe(catchError(this.formatErrors));
  }
}
