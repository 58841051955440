<nav #navbar
  class="navbar navbar-default navbar-expand-lg position-sticky fixed-top navbar-transparent navbar-absolute custom-navbar"
  [class.min-nav]="isSidebarMini" [class.max-nav]="!isSidebarMini">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab btn-round">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      <a class="navbar-brand text-capitalize" href="{{getPath()}}"> {{getTitle()}}</a>
    </div>
    <a class="btn btn-warning btn-round ml-auto logout-btn" role="button" href="#" (click)="onLogout($event)">Logout</a>
    <button mat-button class="navbar-toggler btn-no-ripple text-white" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span>
        <i class="fa fa-bars"></i>
      </span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons">person</i>
            <span class="text-capitalize" style="font-size: large; font-weight: 400;">{{ user?.Full_Name }}</span>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="#" *appShowAuth="'login'" (click)="onLogin($event)">
              <i class="far fa-sign-in mr-2"></i>
              Log In
            </a>
            <a class="dropdown-item" href="#" *appShowAuth="'profile'" (click)="onViewProfile($event)">
              <i class="fas fa-user mr-2"></i>
              Profile
            </a>
            <a class="dropdown-item" href="#" *appShowAuth="'logout'" (click)="onLogout($event)">
              <i class="far fa-sign-out mr-2"></i>
              Log Out
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>