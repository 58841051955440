import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  ApiService,
  JwtService,
  MapService,
  UserService,
  CommonService,
  StateService,
  AppInitService,
} from './services';
import { AuthGuard, NoAuthGuard } from './guards';
import { HttpTokenInterceptor } from './interceptors/http-token.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    JwtService,
    ApiService,
    MapService,
    UserService,
    CommonService,
    StateService,
    AppInitService,
    AuthGuard,
    NoAuthGuard,
  ],
})
export class CoreModule {}
