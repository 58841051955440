import { AbstractControl } from '@angular/forms';

const numberReg = /^\d+$/;

export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
  }
}

export class OnlyNumberValidator {
  static OnlyNumber(control: AbstractControl) {
    const phone = control.get('phone').value;
    const isNumber = new RegExp(numberReg).test(phone);

    if (isNumber) return null;
    control.get('phone').setErrors({ onlyNumber: true });
  }
}
