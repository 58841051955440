import { Component, OnInit, NgZone } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import { UserService, StateService } from './core/services';
import { utility } from './utility';

const TOKEN_INTERVAL = 1 * 1000;
const SECONDS_UNTIL_AUTO_LOGOUT = 32 * 60 * 60 * 1000;

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private _lastAction: any;
  private _isLoggedIn: boolean;
  private _router: Subscription;

  constructor(
    private readonly router: Router,
    private readonly _globalStore: StateService,
    private readonly _userService: UserService,
    private readonly _zone: NgZone
  ) {
    const { width, height } = utility.getWindowSize();
    this._globalStore.setWindowWidth(width);
    this._globalStore.setWindowHeight(height);

    this._lastAction = Date.now();
    this._isLoggedIn = false;
  }

  ngOnInit() {
    this.setWindowResizeEventListener();
    this.setDocumentEventListener();
    this.setIntervalListener();

    this._userService.populate();

    this._userService.isAuthenticated.subscribe((isAuth) => {
      this._isLoggedIn = isAuth;
    });

    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
  }

  setWindowResizeEventListener(): void {
    window.onresize = () => {
      const { width, height } = utility.getWindowSize();
      this._globalStore.setWindowWidth(width);
      this._globalStore.setWindowHeight(height);
    };
  }

  setIntervalListener(): void {
    this._zone.runOutsideAngular(() => {
      setInterval(() => {
        this.checkToken();
      }, TOKEN_INTERVAL);
    });
  }

  setDocumentEventListener(): void {
    this._zone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.onClickDocument());
    });
  }

  onClickDocument(): void {
    this._lastAction = Date.now();
  }

  checkToken(): void {
    const now = Date.now();
    const timeLeft = this._lastAction + SECONDS_UNTIL_AUTO_LOGOUT;
    const diff = timeLeft - now;

    this._zone.run(() => {
      if (diff < 0 && this._isLoggedIn) {
        this._userService.purgeAuth();
        this.router.navigateByUrl('/login');
      }
    });
  }
}
