import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private configSubject: BehaviorSubject<any> = new BehaviorSubject({});
  config = this.configSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private readonly _http: HttpClient) {}

  init() {
    return new Promise<any>((resolve, _ignore) => {
      this._http
        .get('config/config.json')
        .toPromise()
        .then((config) => {
          this.configSubject.next(config);
          resolve();
        });
    });
  }
}
