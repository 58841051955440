import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor() {}

  getToken(): string {
    const token = window.localStorage.getItem('accessToken');
    return JSON.parse(token);
  }

  saveToken(token: string) {
    window.localStorage.setItem('accessToken', JSON.stringify(token));
  }

  destroyToken() {
    window.localStorage.removeItem('accessToken');
  }
}
