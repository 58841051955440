import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators/finalize';

import * as jwt_decode from 'jwt-decode';

import { JwtService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private readonly _router: Router, private readonly _jwt: JwtService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {};
    headersConfig['Content-Type'] = 'application/json';
    headersConfig['Accept'] = 'application/json';

    const token = this._jwt.getToken();
    if (token) {
      const { exp } = jwt_decode(token);
      const curTime = new Date().getTime();

      if (curTime > exp * 1000) {
        this._jwt.destroyToken();
        this._router.navigateByUrl('/login');
        return;
      }

      headersConfig['Authorization'] = `Bearer ${token}`;
    }

    const req = request.clone({ setHeaders: headersConfig });
    return next.handle(req).pipe(finalize(() => {}));
  }
}
