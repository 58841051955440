import {
  Directive,
  Input,
  OnInit,
  ElementRef,
  Renderer2,
  ViewContainerRef,
  TemplateRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { JwtService } from '../../core';

@Directive({
  selector: '[appShowAuth]',
})
export class ShowAuthDirective implements OnInit, OnChanges {
  private _isAuth: boolean;

  private _hasLoginView: boolean;
  private _hasLogoutView: boolean;
  private _hasProfileView: boolean;

  @Input('appShowAuth') sort: string;

  constructor(
    private readonly _vcRef: ViewContainerRef,
    private readonly _templateRef: TemplateRef<any>,
    private readonly _jwt: JwtService
  ) {
    this._hasLoginView = false;
    this._hasLogoutView = false;
    this._hasProfileView = false;
  }

  ngOnInit(): void {
    const token = this._jwt.getToken();
    this._isAuth = Boolean(token);
    this.setVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  setVisibility() {
    switch (this.sort) {
      case 'login':
        if (!this._isAuth && !this._hasLoginView) {
          this._vcRef.createEmbeddedView(this._templateRef);
          this._hasLoginView = false;
        } else {
          this._vcRef.clear();
          this._hasLoginView = true;
        }
        break;
      case 'logout':
        if (this._isAuth && !this._hasLogoutView) {
          this._vcRef.createEmbeddedView(this._templateRef);
          this._hasLogoutView = false;
        } else {
          this._vcRef.clear();
          this._hasLogoutView = true;
        }
        break;
      case 'profile':
        if (this._isAuth && !this._hasProfileView) {
          this._vcRef.createEmbeddedView(this._templateRef);
          this._hasProfileView = false;
        } else {
          this._vcRef.clear();
          this._hasProfileView = true;
        }
        break;
      default:
        break;
    }
  }
}
