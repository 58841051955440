<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img src="/assets/img/logo.png" />
    </div>
  </a>
  <a href="#" class="simple-text logo-normal">
    SPARROW (BETA)
  </a>
</div>

<div class="sidebar-wrapper">
  <ul class="nav">
    <ng-container *ngFor="let menuitem of menuItems">
      <li *appUserRole="menuitem?.role" routerLinkActive="active" class="nav-item">
        <!--If is a single link-->
        <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link d-flex align-items-center">
          <i *ngIf="menuitem.fontAwesome === false" class="material-icons">{{menuitem.icontype}}</i>
          <i *ngIf="menuitem.fontAwesome === true" class="{{menuitem.icontype}}" class="mr-4 ml-1"
            style="font-size: 20px;"></i>
          <p>{{menuitem.title}}</p>
        </a>

        <!--If it have a submenu-->
        <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
          class="nav-link">
          <i class="material-icons">{{menuitem.icontype}}</i>
          <p>{{menuitem.title}}</p>
        </a>

        <!--Display the submenu items-->
        <div *ngIf="menuitem.type === 'sub'">
          <ul class="nav">
            <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
              <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link d-flex align-items-center">
                <i *ngIf="['customer-site', 'company-site'].includes(childitem.ab)"
                  class="far fa-map-marker-alt ml-2 mr-2" style="font-size: 18px;"></i>
                <i *ngIf="childitem.ab === 'customer-estimate'" class="fas fa-usd-circle ml-2 mr-2"
                  style="font-size: 18px;"></i>
                <i *ngIf="childitem.ab === 'company-client'" class="far fa-user ml-2 mr-2" style="font-size: 18px;"></i>
                <span class="sidebar-normal ml-2">{{childitem.title}}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ng-container>
  </ul>

</div>