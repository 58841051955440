export enum MAP_TYPE {
  SATELLITE = 'satellite',
}

export enum MAP_ENTITY {
  SITE = 'site',
  CLIENT = 'client',
}

export enum TREE_CATEGORY {
  UNKNOWN = 'unknown',
  SITE = 'site',
  ESTIMATE = 'estimate',
}

export enum TREE_RATING {
  GOOD = 'Good',
  FAIR = 'Fair',
  POOR = 'Poor',
  DEAD = 'Dead',
  VERY_POOR = 'Very Poor',
}

export enum CUSTOMER_ACCESS {
  CLIENT = 'client',
  SITE = 'site',
  ESTIMATE = 'estimate',
}
